body {
  overflow: hidden;
}

.site-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.ant-layout-header {
  flex-shrink: 0;
}

.ant-layout-content {
  flex: 1;
  overflow-y: auto;
  /* padding: 16px; */
}

.ant-layout-footer {
  flex-shrink: 0;
}

.logo {
  height: 36px;
  margin: 19px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  color: white;
}

.bg {
  /* background-image: url('path/to/image.jpg'); */
  background-size: cover; /* Adjust as needed */
  background-position: center center; /* Adjust as needed */
}
